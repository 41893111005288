import Utils from "../utils/Utils";
import AccountingService from "../services/common/AccountingService";

function getAccountingConfig() {
    return AccountingService.getAccountingData();
}

export default class QuestionCardModel {
    constructor({id, phase, step, concept, question, order, answers = [], relatedStartup = null}) {
        this.id = id;
        this.phase = phase;
        this.step = step;
        this.order = Utils.isset(order) ? order + 1 : order;
        this.concept = concept;
        this.question = question;
        this.answers = answers;
        this.relatedStartup = relatedStartup;
    }

    getInvestmentConfig() {
        const accountingConfig = getAccountingConfig();
        const investmentConfig = accountingConfig.GAME_MONTHS_CAPITAL_PER_STEP[this.step];

        return {
            investmentRightAnswer: investmentConfig?.RIGHT ?? 0,
            investmentWrongAnswer: investmentConfig?.WRONG ?? 0,
        }
    }

    getMonthsConfig() {
        const accountingConfig = getAccountingConfig();
        const monthsToAdvanceConfig = accountingConfig.GAME_MONTHS_TO_ADVANCE_PER_STEP[this.step];

        return {
            monthsRightAnswer: monthsToAdvanceConfig?.RIGHT ?? 0,
            monthsWrongAnswer: monthsToAdvanceConfig?.WRONG ?? 0,
        }
    }
}