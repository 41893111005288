import React, {useEffect, useState} from "react";
import ButtonSpinner from "../../../components/ButtonSpinner";
import "./VerifyConnection.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Status from "./components/Status";
import Utils from "../../../utils/Utils";
import VerifyConnectionSubscriber from "../../../subscribers/VerifyConnectionSubscriber";
import ApiService from "../../../services/common/api/ApiService";
import {API_ENDPOINTS} from "../../../config/API_CONSTANTS";
import EventsUtil from "../../../utils/EventsUtil";
import {GAME_EVENTS} from "../../../config/EVENTS";
import {useTranslation} from "react-i18next";


const STATUS = {
    OK: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
    TESTING: 'info',
    NOT_TESTED: 'muted'
}

const INITIAL_STATUS_STATE = {
    platform: {status: STATUS.NOT_TESTED, message: null},
    service: {status: STATUS.NOT_TESTED, message: null},
    websocket: {status: STATUS.NOT_TESTED, message: null},
    gameData: {status: STATUS.NOT_TESTED, message: null},
}

let lastWebsocketTestInitiatedAt = null;

export default function VerifyConnection() {
    const {t} = useTranslation('pages/public/verify_connection');

    const [isLoading, setIsLoading] = useState(false);
    const [lastTestAt, setLastTestAt] = useState(null);
    const [status, setStatus] = useState(structuredClone(INITIAL_STATUS_STATE));

    const handleWebsocketCallback = (status) => {
        const now = new Date();
        const latency = lastWebsocketTestInitiatedAt ? now - lastWebsocketTestInitiatedAt : -1;

        setStatus(prev => {
            return {
                ...prev,
                websocket: {
                    message: !!status
                        ? t('status.ok_latency', {latency})
                        : t('status.error_invalid_message'),
                    status: !!status ? STATUS.OK : STATUS.ERROR
                }
            }
        });
    }


    const handleTest = async () => {
        const testUuid = 'test-connection-' + Utils.uuid();
        const handleSetStatus = (newStatus) => setStatus(prev => ({...prev, ...newStatus}));

        try {
            setIsLoading(true);
            setLastTestAt(new Date());
            setStatus(structuredClone(INITIAL_STATUS_STATE));


            // ------------- set initial status -------------
            handleSetStatus({
                platform: {message: t('status.ok'), status: STATUS.OK},
                websocket: {message: t('status.testing'), status: STATUS.TESTING}
            })


            // ------------- test websocket conneciton -------------
            await Utils.wait(1000);
            await VerifyConnectionSubscriber.unsubscribe();

            await VerifyConnectionSubscriber.subscribe(testUuid)
                .then(value => {
                    handleSetStatus({
                        websocket: {
                            message: t('status.awaiting_message'),
                            status: STATUS.WARNING
                        }
                    });
                }).catch(err => {
                    handleSetStatus({
                        websocket: {
                            message: t('status.error'),
                            status: STATUS.ERROR
                        }
                    });

                    throw err;
                });


            // ------------- test backend -------------
            handleSetStatus({
                service: {
                    message: t('status.testing'),
                    status: STATUS.TESTING
                }
            });

            await Utils.wait(1000);

            lastWebsocketTestInitiatedAt = new Date();

            const gamePackUrl = await ApiService.post(API_ENDPOINTS.UTILS.VERIFY_CONNECTION, {id: testUuid}, false)
                .then(response => {
                    handleSetStatus({
                        service: {
                            message: t('status.ok'),
                            status: STATUS.OK
                        }
                    });

                    return response.data.gamePack.url;
                }).catch(err => {
                    handleSetStatus({
                        service: {
                            message: t('status.error'),
                            status: STATUS.ERROR
                        }
                    });

                    throw err;
                });


            // ------------- test game data -------------
            handleSetStatus({
                gameData: {
                    message: t('status.testing'),
                    status: STATUS.TESTING
                }
            });

            await Utils.wait(1000);

            await fetch(gamePackUrl).then(response => {
                if (response.ok === true){
                    handleSetStatus({
                        gameData: {
                            message: t('status.ok'),
                            status: STATUS.OK
                        }
                    });
                }else{
                  throw Error('response.ok !== true when fetching game data')
                }
            }).catch(err => {
                handleSetStatus({
                    gameData: {
                        message: t('status.error'),
                        status: STATUS.ERROR
                    }
                });

                throw err;
            })
        } catch (e) {
            console.error(e);
            await VerifyConnectionSubscriber.unsubscribe();
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        EventsUtil.subscribe(GAME_EVENTS.VERIFY_CONNECTION, handleWebsocketCallback);

        return () => {
            EventsUtil.unsubscribe(GAME_EVENTS.VERIFY_CONNECTION, handleWebsocketCallback);
        }
    }, []);

    return (<div id="verify-connection-page" className="h-100 w-100 position-relative">
        <Header/>

        <div className="w-100 d-flex justify-content-center px-lg-5 px-4 pb-3 pt-5">
            <div className="w-100 text-justify" style={{maxWidth: 1200}}>

                <h4 className="mb-2">
                    {t('title')}
                </h4>

                <p>
                    {t('info_1')}
                    <br/>
                    {t('info_2')}
                </p>

                <hr/>


                <Status title={t('platform')} status={status.platform}/>
                <Status title={t('websocket')} status={status.websocket}/>
                <Status title={t('service')} status={status.service}/>
                <Status title={t('game_data')} status={status.gameData}/>

                <p className="mt-3">
                    {t('last_test', {date: lastTestAt ? lastTestAt.toLocaleString() : '-'})}
                </p>

                <ButtonSpinner onClick={handleTest} disabled={isLoading} showAnimation={isLoading}>
                    {t('verify_now')}
                </ButtonSpinner>
            </div>
        </div>

        <Footer/>
    </div>);
}