import React from "react";
import {Button, Modal} from "react-bootstrap";
import StartupCard from "components/cards/StartupCard";
import FounderCard from "components/cards/FounderCard";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";

import "./ConfirmThemeModal.scss";
import SkillCardModel from "models/SkillCardModel";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "hooks/useTranslationForGame";

import startupIcon from "assets/img/board/ic_startup_default.svg";
import founderIcon from "assets/img/board/skill_face.svg";
import valuePropositionIcon from "assets/img/board/ic_value_proposition_default.svg";

export default function ConfirmThemeModal({onConfirm, onClose, theme, cards}) {
    const {t} = useTranslation('pages/tournament/tournament_conception_modal', {keyPrefix: 'components.confirm_modal'});
    const t2 = useTranslation('pages/common/board/conception_modal').t;
    const tfg2 = useTranslationForGame(t2);

    const {startup, founders, foundersIcons, valuePropositions} = cards;

    return (
        <Modal id="confirm-theme-modal" show={true} onHide={onClose} size={'xl'} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="mb-4 mt-n3">
                    {t('texts.subtitle', {theme: theme.title})}
                    <br/>
                    {t('texts.subtitle_2')}
                </p>

                <hr/>

                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center w-100 mb-4">
                        <img src={startupIcon} className="icon" alt="Founder avatar"/>

                        <div className="ml-3">
                            <h6>{tfg2('texts.startup')}</h6>
                            <p className="w-100 mb-0">
                                {tfg2('components.startup_tab.texts.description')}
                            </p>
                        </div>
                    </div>

                    <StartupCard startup={startup} selectable={false}/>
                </div>


                <hr className="my-4"/>

                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center w-100 mb-4">
                        <img src={founderIcon} className="icon" alt="Founder avatar"/>

                        <div className="ml-3">
                            <h6>{tfg2('texts.founders')}</h6>
                            <p className="w-100 mb-0">
                                {tfg2('components.founders_tab.texts.description')}
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center w-100" style={{gap: '10px 0px'}}>
                        <FounderCard founder={founders[0]}
                                     icon={SkillCardModel.getFullPathForIcon(foundersIcons[0])}
                                     selectable={false}/>

                        <FounderCard founder={founders[1]}
                                     icon={SkillCardModel.getFullPathForIcon(foundersIcons[1])}
                                     selectable={false}/>
                    </div>
                </div>

                <hr className="my-4"/>

                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center w-100 mb-4">
                        <img src={valuePropositionIcon} className="icon" alt="Founder avatar"/>

                        <div className="ml-3">
                            <h6>{tfg2('texts.value_propositions')}</h6>
                            <p className="w-100 mb-0">
                                {tfg2('components.value_propositions_tab.texts.description')}
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center w-100" style={{gap: '10px 0px'}}>
                        <ValuePropositionCard valueProposition={valuePropositions[0]}
                                              selectable={false}
                                              layout={VALUE_PROPOSITION_LAYOUT.FULL}/>

                        <ValuePropositionCard valueProposition={valuePropositions[1]}
                                              selectable={false}
                                              layout={VALUE_PROPOSITION_LAYOUT.FULL}/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>{t('buttons.confirm')}</Button>
                <Button variant="secondary" onClick={onClose}>{t('buttons.back')}</Button>
            </Modal.Footer>
        </Modal>
    )
}