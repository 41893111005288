import {useTranslation} from "react-i18next";

/**
 * Preloads some translation namespaces that are used by internal components of TournamentConceptionModal
 *
 * This is needed because when we use the syntax:
 *
 *    const t2 = useTranslation('pages/common/board/conception_modal').t;
 *
 * it does not trigger the React's suspense functionality, and the translation file is not loaded properly
 *
 * @returns {null}
 * @constructor
 */
export default function PreloadTranslationFiles() {
    const {t} = useTranslation('pages/common/board/conception_modal');

    return null;
}