import {ACCELERATION_BONUSES, GAME_STEPS} from "./CONSTANTS";


// ----------------------- ACCOUNTING -----------------------
const GAME_PITCH_BONUS = 10;
const GAME_INITIAL_CAPITAL = 0;
const GAME_INITIAL_ACCELERATION = 0;

const GAME_COST_PER_STEP = {
    [GAME_STEPS.MVP]: 0,
    [GAME_STEPS.PITCH_1]: 0,

    [GAME_STEPS.BETA]: 0,
    [GAME_STEPS.GTM]: 0,
    [GAME_STEPS.GROWTH_MODEL]: 0,
    [GAME_STEPS.PITCH_2]: 0,

    [GAME_STEPS.SALES_MACHINE]: 0,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 0,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 0,
    [GAME_STEPS.PITCH_3]: 0
};

const GAME_REVENUE_PER_STEP = {
    [GAME_STEPS.BETA]: 80,
    [GAME_STEPS.GTM]: 160,
    [GAME_STEPS.GROWTH_MODEL]: 320,
    [GAME_STEPS.PITCH_2]: 640,

    [GAME_STEPS.SALES_MACHINE]: 100,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 100,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 100,
    [GAME_STEPS.PITCH_3]: 100,
};

const GAME_MONTHS_CAPITAL_PER_STEP = {
    [GAME_STEPS.BETA]: {RIGHT: 60000, WRONG: 50000},
    [GAME_STEPS.GTM]: {RIGHT: 60000, WRONG: 50000},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 60000, WRONG: 50000},

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 0, WRONG: 0},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 0, WRONG: 0},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 0, WRONG: 0}
};

const GAME_MONTHS_TO_ADVANCE_PER_STEP = {
    [GAME_STEPS.CONCEPTION]: 1,
    [GAME_STEPS.MVP]: {RIGHT: 1, WRONG: 2},
    [GAME_STEPS.PITCH_1]: 1,

    [GAME_STEPS.BETA]: {RIGHT: 5, WRONG: 7},
    [GAME_STEPS.GTM]: {RIGHT: 5, WRONG: 7},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 5, WRONG: 7},
    [GAME_STEPS.PITCH_2]: 1,

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 0, WRONG: 0},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 0, WRONG: 0},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 0, WRONG: 0},
    [GAME_STEPS.PITCH_3]: 4,
};

const GAME_TEAM_SPEED_BY_ADVANCED_MONTHS = {
    [GAME_STEPS.CONCEPTION]: {FAST: 1, SLOW: 0},
    [GAME_STEPS.MVP]: {FAST: 1, SLOW: 2},
    [GAME_STEPS.PITCH_1]: {FAST: 1, SLOW: 2},

    [GAME_STEPS.BETA]: {FAST: 8, SLOW: 9},
    [GAME_STEPS.GTM]: {FAST: 13, SLOW: 16},
    [GAME_STEPS.GROWTH_MODEL]: {FAST: 18, SLOW: 23},
    [GAME_STEPS.PITCH_2]: {FAST: 23, SLOW: 30},

    [GAME_STEPS.SALES_MACHINE]: {FAST: 23, SLOW: 28},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {FAST: 28, SLOW: 34},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {FAST: 33, SLOW: 39},
    [GAME_STEPS.PITCH_3]: {FAST: 37, SLOW: 43},
};

const GAME_PITCH_INVESTMENT = {
    [GAME_STEPS.PITCH_1]: {1: 180000, 2: 180000, 3: 180000, 4: 180000},
    [GAME_STEPS.PITCH_2]: {1: 400000, 2: 300000, 3: 220000, 4: 180000},
    [GAME_STEPS.PITCH_3]: {1: 400000, 2: 300000, 3: 220000, 4: 180000},
};



// ----------------------- ACCELERATION BONUSES -----------------------
/** format is: {CARD_COUNT: ACCELERATION_BONUS} */
const ACCELERATION_BONUS_VALUES = {
    [ACCELERATION_BONUSES.IMPLEMENTATION]: {
        4: 10,
        8: 20
    },
    [ACCELERATION_BONUSES.MONITORING]: {
        4: 10,
        8: 20
    },
    [ACCELERATION_BONUSES.REPORTING]: {
        4: 10,
        8: 20
    }
};


// ----------------------- EXPORTS -----------------------
const ACCOUNTING_IA_CONFIG ={
    ACCELERATION_BONUS_VALUES,
    GAME_COST_PER_STEP,
    GAME_REVENUE_PER_STEP,
    GAME_MONTHS_CAPITAL_PER_STEP,
    GAME_MONTHS_TO_ADVANCE_PER_STEP,
    GAME_TEAM_SPEED_BY_ADVANCED_MONTHS,
    GAME_PITCH_BONUS,
    GAME_INITIAL_CAPITAL,
    GAME_PITCH_INVESTMENT,
    GAME_INITIAL_ACCELERATION
};


export default ACCOUNTING_IA_CONFIG;